/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}     
* {font-family:'Open Sans' !important;}    
html,
body {
	height: 100%;
	width: 100%;
}

body {
	margin: 0;
	padding: 0;
}

/* Dropdown auf Klick */

.dropdown {
	display: inline-block;
	position: relative;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 70vw;
	overflow: auto;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown a:hover {
	background-color: #f1f1f1;
}

.show {
	display: block;
}

.nav-collapsible-links-active {
	background: #ebdfda !important;
}



/* Hilfe-Einblendung The Modal (background) */

.modal2 {
	display: none;
	/* Hidden by default */
	position: fixed;
	/* Stay in place */
	z-index: 12;
	/* Sit on top */
	padding-top: 50px;
	/* Location of the box */
	left: 0;
	top: 0;
	width: 100%;
	/* Full width */
	height: 100%;
	/* Full height */
	background-color: rgb(0, 0, 0);
	/* Fallback color */
	background-color: rgba(0, 0, 0, 0.4);
	/* Black w/ opacity */
}


/* Modal Content */

.modal-content2 {
	position: relative;
	background-color: #fefefe;
	margin: auto;
	padding: 0;
	border: 1px solid #888;
	width: 90%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-animation-name: animatetop;
	-webkit-animation-duration: 0.4s;
	animation-name: animatetop;
	animation-duration: 0.4s;
}


/* Add Animation */

@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}

@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}


/* The Close Button */

.close {
	color: white;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.modal-header {
	padding: 5px 16px;
	color: white;
}

.modal-body {
	padding: 5px 16px;
	max-height: calc(100vh - 120px);
	overflow-y: auto;
}

.modal-footer {
	padding: 2px 16px;
}

/* Leaflet
**************/

#mDia {
	visibility: hidden;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	/*background-color:  rgba(220,160,140,0.5);*/
	z-index: 1000;
}

#mDia div {
	height: 50px;
	margin-top: calc(100vh - 50px);
	background-color: #fff;
	border: 1px solid #000;
	padding: 15px;
	text-align: center;
}

/* #am NRW */
#map, #kmap, #lsmap {
	min-height: 250px;
	height: calc(50vh);
	margin-bottom: 30px;
	z-index: 2;
}

.leaflet-control a {
	background: inherit;
	color: inherit;
}

.leaflet-control-attribution {
	max-width: 300px;
	width:80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow:ellipsis;
}

.leaflet-control .attributionLong {
	white-space: normal;
	max-width: 92%;
	width:92%;
}

.leaflet-html-legend {
	position: absolute !important;
	left: 50px;
	bottom: 5px;
	overflow-y: hidden !important;
}

.leaflet-control-layers-overlays input[type=checkbox][disabled]:before {
	background-color: #E1E1E1 !important; 
  }

.leaflet-control-attribution a {
	font-size: 11px !important;
}

.leaflet-control-layers-toggle {
	background-image: url(../map/leaflet/images/layers.png) !important;
	width: 36px !important;
	height: 36px !important;
	background-repeat: no-repeat !important;
	background-position: 50% 50% !important;
}

.leaflet-control-layers-overlays input {
	margin: 0 8px;
}
/* FullExtent */

.mapdisplay {
	position: fixed;
	/* Stay in place */
	z-index: 12;
	/* Sit on top */
	left: 0;
	top: 0;
	width: 100%;
	/* Full width */
	height: 100%;
	/* Full height */
}


/* Farben
**************/

body {
	background: #e0e0e0;
}

.color--dark--home {
	color: #616161;
}


/* Runder Button und Anzeige (dunkle Farbstufe) */

#section-home .anzeige,
#section-home .helpbutton,
.bg--dark--home {
	background: #616161 !important;
	/* braun sehr dunkel hier darken 2*/
	color: white;
}

#section-info .anzeige,
#section-info .helpbutton,
#section-nutzbed .anzeige,
#section-nutzbed .helpbutton,
.bg--dark--info {
	background: #616161 !important;
	color: white;
}

#section-print .anzeige,
#section-print .helpbutton,
.bg--dark--print {
	background: #9b341b !important;
	color: white;
}

#section-A .anzeige,
#section-A .helpbutton,
#section-A .printbutton,
.bg--dark--A {
	background: #9b341b !important;
	color: white;
}

#section-R .anzeige,
#section-R .helpbutton,
.bg--dark--R {
	background: #00695c !important;
	color: white;
}

#section-K .anzeige,
#section-K .helpbutton,
.bg--dark--K {
	background: #5d4037 !important;
	/* braun sehr dunkel hier darken 2*/
	color: white;
}

#section-LS .anzeige,
#section-LS .helpbutton,
.bg--dark--LS {
	background: #f57f17 !important;
	/* gelb */
	color: white;
}

#section-C .anzeige,
#section-C .helpbutton,
#section-C .addbutton,
#section-C .removebutton,
.bg--dark--C {
	background: #558b2f !important;
	color: white;
}

#section-P .anzeige,
#section-P .helpbutton,
.bg--dark--P {
	background: #827717 !important;
	color: white;
}


/* Header-Farben und Hintergrund bei Anzeige (mittlere Farbstufe) */

#section-home header,
.dropbtn:hover,
.dropbtn:focus,
.radchecked:hover,
#nav--super-vertical-responsive:checked + label {
	background: #9e9e9e;
	/* grey darken-2 */
}

#section-info header,
#section-info .header2,
#section-nutzbed header,
#section-nutzbed .header2,
.bg--info,
#section-info .modal-header,
#section-info .modal-footer,
#section-info .helpbutton-anzeige,
#section-nutzbed .modal-header,
#section-nutzbed .modal-footer,
#section-nutzbed .helpbutton-anzeige {
	background: #9e9e9e;
	/* grey */
}

#section-print header,
#section-print .header2,
.bg--print,
#section-print .modal-header,
#section-print .modal-footer,
#section-print .helpbutton-anzeige {
	background: #c48875;
}

#section-A header,
#section-A .header2,
#section-A footer,
.bg--A,
#section-A .modal-header,
#section-A .modal-footer,
#section-A .helpbutton-anzeige {
	background: #c48875 !important;
	fill:white;
	/* Logo-Farbe */
}

#section-R header,
#section-R .header2,
#section-R footer,
.bg--R,
#section-R .modal-header,
#section-R .modal-footer,
#section-R .helpbutton-anzeige {
	background: #009688 !important;
	/* teal */
}

#section-K header,
#section-K .header2,
#section-K footer,
.bg--K,
#section-K .modal-header,
#section-K .modal-footer,
#section-K .helpbutton-anzeige {
	background: #8d6e63 !important;
	/*braun sehr dunkel hier lighten 1*/
}

#section-LS header,
#section-LS .header2,
#section-LS footer,
.bg--LS,
#section-LS .modal-header,
#section-LS .modal-footer,
#section-LS .helpbutton-anzeige {
	background: #fbc02d !important;
	/*gelb darken 2 */
}

#section-C header,
#section-C .header2,
#section-C footer,
.bg--C,
.dropbtn,
#section-C .modal-header,
#section-C .modal-footer,
#section-C .helpbutton-anzeige {
	background: #8bc34a !important;
}

#section-P header,
#section-P .header2,
#section-P footer,
.bg--P,
#section-P .modal-header,
#section-P .modal-footer,
#section-P .helpbutton-anzeige {
	background: #c0ca33 !important;
}


/* Hintergrund der Seite(helle Farbstufe) */

.bg--light--home,
.bg--light--info {
	background: #e0e0e0 !important;
}

#section-A footer .btn--raised,
.bg--light--A,
.bg--light--print {
	background: #e8cebf !important;
}

#section-R footer .btn--raised,
.bg--light--R {
	background: #80cbc4 !important;
}

#section-K footer .btn--raised,
.bg--light--K {
	background: #bcaaa4 !important;
}

#section-LS footer .btn--raised,
.bg--light--LS {
	background: #ffee58 !important;
}

#section-C footer .btn--raised,
.bg--light--C {
	background: #c5e1a5 !important;
}

#section-P footer .btn--raised,
.bg--light--P {
	background: #dce775 !important;
}

#section-A footer .btn--raised,
#section-R footer .btn--raised,
#section-K footer .btn--raised,
#section-LS footer .btn--raised,
#section-C footer .btn--raised,
#section-P footer .btn--raised {
	color:black;
	background: #ba68c8 !important;
}


/* Runde Buttons, Methoden-Auswahl */

.radunchecked,
.dropbtn {
	/*background-image: radial-gradient(circle, #4caf50 1%, #80c883 15%, transparent 30%);
  background-color: #4caf50; */
	color: black;
}

.radunchecked:hover {
	/* Logo-Blau */
	background-color: #5e92b9;
}


/* Anpassungen, Ueberschreiben von Surface CSS-Styling
*****************************************************/
@media screen and (max-width: 1200px) {
    .nav--super-vertical {
        padding-top: 20px;
    }    
}

footer {
  padding-top: 1px;
  padding-bottom: 1px;
}

/* HTML Formatting Elements */

strong {
	font-weight: 700;
}

i {
  font-style: italic;
}

ul.list {
	list-style-type: disc;
}

ol {
  white-space: normal; 
}

td,
tr {
	padding: 10px;
}

tr {
	line-height:initial;
}

/* HTML Form Elements */
aside {
	min-width: 280px;
	z-index: 10 !important;
}

textarea {
	width: 100%;
}

select {
	width: 99%;
	margin-bottom: 20px;
}

label {
	display: inline-block;
	max-width: 100%;
	margin: 10px 0;
}

legend {
	font-weight: bold;
	margin-bottom: 20px;
}

fieldset {
	margin-bottom: 30px;
}

fieldset:last-child {
	margin-bottom: 0;
}

.tile,
.card {
	margin-bottom: 20px;
}

input[type='text'],
input[type='range'] {
	display: inline !important;
}

input[type='range'] {
	width: calc(100% - 100px);
	margin-left: 5px;
	max-width: 300px;
	min-width: 100px;
	vertical-align: middle;
	padding: 15px 0;
}


/*
input[type=range] {
  width:300px;
  max-width:300px;
  min-width:100px;
  vertical-align: middle;
  padding: 15px 0;
}
*/

.closebutton {
	background-color: red;
	height: 40px;
	z-index: 20000;
	position: fixed;
	right: 10px;
	top: 10px;
	width: 40px;
}

.helpbutton,
.printbutton {
	float: right;
	width: 40px;
	height: 40px;
}

/* C-Faktor-Sachen */
.removebutton {
	width: 30px;
	height: 30px;
	padding:0px;
	margin: -5px 15px 0px!important;
}

/*
.C-fruchtfolgen-berechnung-glied {
	border: 1px solid #888;
	padding: 10px;
}

.C-hinweis {
	color:red;
}

.helpbutton-inCard {
	margin-left: 10px;
	margin-top: 10px;
}

*/

.C-fruchtfolgen-berechnung-glied>label {
	font-weight: 700;
	min-height:60px;
}

.C-fruchtfolgen-berechnung-glied:last-child {
	margin-bottom: 30px;
}


.addbutton {
margin-right: 20px;
}
.helpbutton-anzeige {
	margin-right: 8.33333%;
	margin-top: 10px;
}

input[type='range']::-ms-track {
	height: 3px;
	padding-top: 3px;
	margin-top: -2px;
	padding-bottom: 3px;
	margin-bottom: -2px;
}

input[type='range']::-ms-thumb {
	height: 16px;
	width: 16px;
	margin-top: 0;
}


/* eigene CSS Klassen  
*********************/
#section-A .mob-printbutton {
	display: none;
}
@media screen and (max-width: 640px) {
	#section-A .mob-printbutton {
		position: fixed;
		bottom: 5px;
		right: -20px;
		display: inline-block;
		cursor: default;
		box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5), 3px 3px 3px rgba(0, 0, 0, 0.25);
	}
	#section-A .printbutton {
		display: none;
	}
}

.underline {
	text-decoration: underline;
}

.formelbtn {
	position: absolute;
	right: 10px;
	top: 10px;
}

.header2 {
	width: 100%;
}

.anzeige {
	padding-left: 8.33333%;
}

.anzeigetxt {
	padding-left: 18px;
}

.anzeigetxt-bold {
  	font-weight: 700;
	font-size: 18px;
	color: #ffe400; 
}

.mainCards {
	cursor: pointer;
	margin: 10px;
	min-width: 25% !important;
}


/* braucht man das??
.dropdown ul li a {
	overflow:visible;
}
*/

.hidden {
	display: none !important;
}

.slidertxt {
	width: 50px;
	/*70px !important;*/
	display: inline-block;
	vertical-align: middle;
}

.nobr {
	white-space: nowrap;
}

figcaption {
	padding: 5px 0px 20px;
	font-size:14px;
}

img.imgNormal {
	width:auto !important;
}

.imgIcon {
	float:left;
	margin-right: 5px;
}

h6.help {
	font-weight: 700;
	font-size: 16px; 
}

/* Sticky-Handling */

.sticky {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -o-sticky;
	position: -ms-sticky;
	position: sticky;
	top: 0;
	z-index: 9;
}


/* Nach oben setzen des Menues ueber sticky */

#nav--super-vertical-responsive:checked + label {
	z-index: 11;
}

#nav--super-vertical-responsive:checked ~ aside {
	z-index: 10;
}


/* Bodendiagramm und RDeutschlandKarte */


/* SVG einpassen */

#bodendiagrammSVG {
	max-width: 411px;
	max-height: 398px;
}

.bodendiagrammSVG {
	width: 411px;
	/* IE */
	height: 398px;
	/* IE */
}

#rfaktorimgSVG {
	max-width: 390px;
	max-height: 464px;
}

.rfaktorimgSVG {
	width: 390px;
	/* IE */
	height: 464px;
	/* IE */
}

#rfaktorimgSVG svg,
#bodendiagrammSVG svg {
	height: 100%;
	display: block;
	max-width: 100%;
}

#K-analyse-feinboden-texturanalyse-andererboden-sand::-webkit-slider-thumb {
	background: grey;
}

#K-analyse-feinboden-texturanalyse-andererboden-sand::-moz-range-thumb {
	background: grey;
}


/* Sonstige Responsive Sachen die das Layout regeln */

input,
select {
	font-size: 1em;
}

@media screen and (max-width: 800px) {
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 24.8px;
	}
	h4 {
		font-size: 20.8px;
	}
	header {
		padding-top: 37px;
		/* Runde Buttons sind nun ueber der Ueberschrift */
	}
}

@media screen and (max-width: 520px) {
	h1 {
		font-size: 28px;
	}
	h2 {
		font-size: 24.8px;
	}
	h3 {
		font-size: 20.8px;
	}
	h4 {
		font-size: 18px;
	}
	input,
	select {
		font-size: 1em;
	}
	.formelbtn {
		display: none !important;
	}
	.modal2 {
		padding-top: 0;
		/* Location of the box */
	}
	.modal-content2 {
		width: 100%;
	}
	.modal-body {
		max-height: calc(100vh - 80px);
	}
}

@media screen and (max-width: 400px) {
	/*input[type=range] {
        width: 200px;
    }
	*/
}

@media screen and (min-width: 900px) {
	.modal-content2 {
		width: 60%;
	}
}

@media screen and (max-width: 700px) {
	input[type='range']::-webkit-slider-thumb {
		margin-top: -15px;
		height: 30px;
		width: 30px;
	}
	input[type='range']::-moz-range-thumb {
		margin-top: -15px;
		height: 30px;
		width: 30px;
	}
	input[type='range']::-ms-thumb {
		height: 16px;
		width: 16px;
		margin-top: 0;
	}
}


/* Snackbar */

.paper-snackbar {
	-webkit-transition-property: opacity, bottom, left, right, width, margin, border-radius;
	transition-property: opacity, bottom, left, right, width, margin, border-radius;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	min-height: 14px;
	position: fixed;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 18px 24px;
	left: 0;
	bottom: 0;
	z-index: 1040;
}

.paper-snackbar .action {
	text-transform: uppercase;
}

.paper-snackbar {
	background-color: gray;
	color: white;
}

.actionTwice {
	margin-top: 5px;
	margin-right: 5px;
}

@media (min-width: 640px) {
	.paper-snackbar {
		/*
    Desktop:
      Single-line snackbar height: 48 dp tall
      Minimum width: 288 dp
      Maximum width: 568 dp
      2 dp rounded corner
      Text: Roboto Regular 14 sp
      Action button: Roboto Medium 14 sp, all-caps text
      Default background fill: #323232 100%
    */
		min-width: 288px;
		max-width: 568px;
		display: -webkit-inline-box;
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex;
		border-radius: 2px;
		margin: 24px;
		bottom: -100px;
	}
}

@media (max-width: 640px) {
	.paper-snackbar {
		/*
    Mobile:
      Single-line snackbar height: 48 dp
      Multi-line snackbar height: 80 dp
      Text: Roboto Regular 14 sp
      Action button: Roboto Medium 14 sp, all-caps text
      Default background fill: #323232 100%  
    */
		left: 0;
		right: 0;
	}
}

.animate {
	transition: all 2s ease;
}

/* font awesome SVG-raw */

svg:not(:root).svg-inline--fa {
	overflow: visible;
}
.svg-inline--fa.fa-w-18 {
	width: 1.125em;
}
.svg-inline--fa.fa-w-16 {
	width: 1em;
}
.svg-inline--fa.fa-w-14 {
	width: 0.875em;
}
.svg-inline--fa {
	display: inline-block;
	font-size: inherit;
	height: 1em;
	overflow: visible;
	vertical-align: -0.125em;
}
